<template>
  <div>
    <shipblu-card class="p-4" :cardLoading="cardLoader">
      <div class="gap-4 justify-between sm:flex items-center">
        <div class="w-full" style="max-width: 500px;">
          <div class="pb-3 border-dashed gap-x-4 border-t-0 border-r-0 border-l-0 border-grey font-medium text-xl flex flex-wrap items-center border text-blue-100">
            <p>{{$t('Cash on the way')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto text-lg">
              <p>{{('EGP')}}</p>
              <p>{{financials.cash_on_the_way.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <div class="flex pt-5 text-grey-dark gap-x-2">
            <p class="text-gray">{{$t('Cash Collection Balance')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{financials.cash_collection_balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <div class="flex pt-3 text-grey-dark">
            <p class="text-gray">{{$t('Refunds In Progress')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{financials.refunds_in_progress ? financials.refunds_in_progress.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00}}</p>
            </div>
          </div>
          <div class="flex pt-3 text-grey-dark">
            <p class="text-gray">{{$t('Service Fees Due')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{financials.service_fees_due.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
          <div class="flex pt-3 text-grey-dark">
            <p class="text-gray">{{$t('Amounts On Hold')}}</p>
            <div class="flex justify-between w-36 flex-shrink-0 ml-auto">
              <p class="text-gray">{{('EGP')}}</p>
              <p>{{financials.amounts_on_hold.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
        </div>
        <div class="sm:block hidden w-64">
          <img src="@/assets/images/pages/statment-pay.png" class="max-w-full" height="115">
        </div>
      </div>
      <div class="mt-6 items-center flex justify-between">
        <p>{{$t('Receive your cash for completed orders today!')}}</p>
        <vx-tooltip v-if="isBillingDay()" position="top" color="warning" :text="`${$t('Instant Payout isn\'t available on the statement issuing day')}`">
          <div class="cursor-not-allowed">
            <div class="pointer-events-none unavailable-action flex p-3">
              <p class="material-symbols-outlined text-xl">bolt</p>
              <span class="ml-2 font-semibold">{{ $t('Instant Payout')}}</span>
            </div>
          </div>
        </vx-tooltip>
        <div v-else @click="addInstantPayout" id="instant-payout-btn" type="filled" class="vs-con-loading__container cursor-pointer flex items-center leading-tight gap-1 bg-primary rounded-lg text-white p-3 font-semibold">
          <p class="material-symbols-outlined text-xl">bolt</p>
          <p>{{$t('Instant Payout')}}</p>
        </div>
      </div>
    </shipblu-card>
    <instant-payout-modal :instant="instant" v-if="instantPayoutModal" @instantPayoutModal="instantPayoutModal = $event" />
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import InstantPayoutModal from './InstantPayoutModal.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['financials', 'cardLoader', 'merchant'],
  data () {
    return {
      instantPayoutModal: false,
      instant: {
        available_cod: 0.0,
        service_fees_due: 0.0,
        on_hold_amount: 0.0,
        instant_payout_fees: 0.0,
        transfer_fees: 0.0,
        payout_amount: 0.0
      }
    }
  },
  methods: {
    getEgyptTime () {
      const egyptTimeZone = 'Africa/Cairo'
      const now = new Date()
      const daysOptions = { timeZone: egyptTimeZone, weekday: 'long' }
      const egyptDay = now.toLocaleDateString('en-US', daysOptions)

      const hoursOptions = { timeZone: egyptTimeZone, hour: '2-digit', minute: '2-digit', hour12: false }
      const egyptTime = now.toLocaleTimeString('en-US', hoursOptions)
      return [egyptTime, egyptDay]
    },
    addInstantPayout () {
      if ((Number(this.getEgyptTime()[0].split(':')[0]) >= 7 && Number(this.getEgyptTime()[0].split(':')[0]) < 11) && this.getEgyptTime()[1] !== 'Friday' && this.getEgyptTime()[1] !== 'Saturday') {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#instant-payout-btn',
          scale: 0.45
        })
        sendRequest(true, false, this, 'api/v1/instant/payouts/?dry_run=True', 'get', null, true,
          (response) => {
            this.instant = response.data
            this.instantPayoutModal = true
            this.$vs.loading.close('#instant-payout-btn > .con-vs-loading')
          }, (error) => {
            common.notifyResponseError(this, error)
            this.$vs.loading.close('#instant-payout-btn > .con-vs-loading')
          }
        )
      } else {
        this.$vs.notify({
          color:'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Instant Payout should be requested before 11 AM from Sunday to Thursday, excluding holidays.',
          position: 'top-center'
        })
      }
    },
    isBillingDay () {
      const now = new Date()
      // Get the current day of the week (Saturday = 1, Sunday = 2, ..., Thursday = 6, Friday = 7)
      const currentDay = new Intl.DateTimeFormat('en-US', {
        timeZone: 'Africa/Cairo',
        weekday: 'long'
      }).format(now)
      const dayMap = {
        Saturday: 1,
        Sunday: 2,
        Monday: 3,
        Tuesday: 4,
        Wednesday: 5,
        Thursday: 6,
        Friday: 7
      }
      const todayIndex = dayMap[currentDay]
      return this.merchant.billing_days ? this.merchant.billing_days.includes(todayIndex) : ''
    }
  },
  components: {
    ShipbluCard,
    InstantPayoutModal
  }
}
</script>